<template>
	<div class="sign-in">
		<LoaderDefault v-if="this.isLoading"></LoaderDefault>

		<a-row v-else type="flex" justify="space-around">

			<!-- Sign In Form Column -->
			<a-col :span="24" :md="12" :lg="{ span: 12, offset: 0 }" :xl="{ span: 6, offset: 0 }" class="col-form">
				<h1 class="mb-15">Iniciar Sesión</h1>
				<h5 class="font-regular text-muted">Ingrese sus datos de acceso</h5>

				<!-- Sign In Form -->
				<a-form id="components-form-demo-normal-login" :form="form" class="login-form" @submit="handleSubmit"
					:hideRequiredMark="true">
					<a-form-item class="mb-10" label="Correo" :colon="false">
						<a-input v-decorator="[
							'email',
							{ rules: [{ required: true, message: 'Por favor ingresa tu correo!' }] },
						]" placeholder="Email" />
					</a-form-item>
					<a-form-item class="mb-5" label="Contraseña" :colon="false">
						<a-input v-decorator="[
							'password',
							{ rules: [{ required: true, message: 'Por favor ingresa tu contraseña!' }] },
						]" type="password" placeholder="Contraseña" />
					</a-form-item>

					<a-form-item>
						<a-button type="primary" block html-type="submit" class="login-form-button">
							INICIAR SESIÓN
						</a-button>
					</a-form-item>
				</a-form>
				<!-- / Sign In Form -->
			</a-col>
			<!-- / Sign In Form Column -->
		</a-row>
	</div>
</template>

<script>
import { auth } from '../../firebaseConfig';
import LoaderDefault from '../components/Loaders/LoaderDefault.vue';

export default ({
	component: {
		LoaderDefault
	},
	data() {
		return {
			isLoading: false,
			errorEmailInvalid: "auth/invalid-email",
			errorUserDisabled: "auth/user-disabled",
			errorWrongPassword: "auth/wrong-password",
			errorUserNotFount: "auth/user-not-found",
		};
	},
	beforeCreate() {
		this.form = this.$form.createForm(this, { name: "normal_login" });
	},
	methods: {
		handleSubmit(e) {
			e.preventDefault();
			this.form.validateFields((err, values) => {
				if (!err) {
					this.isLoading = true;
					auth.signInWithEmailAndPassword(values.email, values.password)
						.then(() => {
							this.$router.push({ path: "/dashboard" });
						})
						.catch((error) => {
							this.isLoading = false;
							switch (error.code) {
								case this.errorEmailInvalid:
									this.showMessageError("El usuario ingresado es incorrecto, por favor ingresa tu usuario correctamente");
									break;
								case this.errorUserDisabled:
									this.showMessageError("Cuenta deshabilitada, por favor comunicate con los administradores");
									break;
								case this.errorWrongPassword:
									this.showMessageError("Usuario o contraseña invalidos, por favor ingrese sus datos correctamente");
									break;
								case this.errorUserNotFount:
									this.showMessageError("El usuario ingresado no se encuentra registrado, por favor comunicarse con los administradores");
									break;
							}
						});
				}
			});
		},
		showMessageError(message) {
			this.$swal
				.fire({
					title: "Ha ocurrido un error",
					text: message + " o intenta más tarde.",
					icon: "error",
					showCancelButton: false,
					confirmButtonText: "Aceptar",
					confirmButtonColor: "#45A825",
					cancelButtonText: "Cancelar",
				});
		}
	},
	components: { LoaderDefault }
})

</script>

<style lang="scss">
.col-form {
	margin: 5em auto;
	padding: 2em;
	background-color: white;
	border-radius: 1em;
    box-shadow: rgb(203, 201, 201) 4px 4px 18px 1px;

}
</style>